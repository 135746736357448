import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";
import store from "../store";
Vue.use(Router);

let routes = [];
routes = [
    {
        path: "/",
        name: "Login",
        meta: {
            title: "宏瓴科技",
        },
        component: () => import("../views/login/index.vue"),
    },
    {
        path: "/system",
        component: Layout,
        redirect: "/system/userManage",
        name: "system",
        meta: { title: "系统管理", icon: "el-icon-s-tools" },
        children: [
            {
                path: window.__POWERED_BY_QIANKUN__
                    ? "/userManages"
                    : "userManages",
                name: "UserManages",
                alias: "/vue/userManages",
                component: () => import("@/views/system/userManages.vue"),
                meta: { title: "用户管理", icon: "el-icon-s-marketing" },
            },
            {
                path: window.__POWERED_BY_QIANKUN__
                    ? "/userManage"
                    : "userManage",
                name: "UserManage",
                alias: "/vue/userManage",
                component: () => import("@/views/system/userManage.vue"),
                meta: { title: "用户管理-内", icon: "el-icon-s-marketing" },
            },
            {
                path: window.__POWERED_BY_QIANKUN__
                    ? "/roleAdmin"
                    : "roleAdmin",
                name: "RoleAdmin",
                alias: "/vue/roleAdmin",
                component: () => import("@/views/system/roleAdmin.vue"),
                meta: { title: "角色管理", icon: "el-icon-s-marketing" },
            },
            {
                path: window.__POWERED_BY_QIANKUN__
                    ? "/menuAdmin"
                    : "menuAdmin",
                name: "menuAdmin",
                alias: "/vue/menuAdmin",
                component: () => import("@/views/system/menuAdmin.vue"),
                meta: { title: "菜单管理", icon: "el-icon-s-marketing" },
            },
            {
                path: window.__POWERED_BY_QIANKUN__
                    ? "/tenantAdmin"
                    : "tenantAdmin",
                name: "tenantAdmin",
                component: () => import("@/views/system/tenantAdmin.vue"),
                meta: { title: "租户管理", icon: "el-icon-s-marketing" },
            },
            {
                path: window.__POWERED_BY_QIANKUN__
                    ? "/postAdmin"
                    : "postAdmin",
                name: "postAdmin",
                component: () => import("@/views/system/postAdmin.vue"),
                meta: { title: "岗位管理", icon: "el-icon-s-marketing" },
            },
            {
                path: window.__POWERED_BY_QIANKUN__
                    ? "/deptAdmin"
                    : "deptAdmin",
                name: "DeptAdmin",
                component: () => import("@/views/system/deptAdmin.vue"),
                meta: { title: "组织架构管理", icon: "el-icon-s-marketing" },
            },
        ],
    },
    // {
    //     path: "/ruleEngine",
    //     component: Layout,
    //     redirect: "/ruleEngine/ruleAdmin",
    //     name: "RuleEngine",
    //     meta: { title: "规则引擎", icon: "el-icon-s-check" },
    //     children: [
    //         {
    //             path: "featureAdmin",
    //             name: "FeatureAdmin",
    //             component: () => import("@/views/ruleEngine/featureAdmin.vue"),
    //             meta: { title: "特征管理", icon: "el-icon-s-marketing" },
    //         },
    //         {
    //             path: "ruleAdmin",
    //             name: "RuleAdmin",
    //             component: () => import("@/views/ruleEngine/ruleAdmin.vue"),
    //             meta: { title: "规则管理", icon: "el-icon-s-marketing" },
    //         },
    //         {
    //             path: "strategyAdmin",
    //             name: "StrategyAdmin",
    //             component: () => import("@/views/ruleEngine/strategyAdmin.vue"),
    //             meta: { title: "策略管理", icon: "el-icon-s-marketing" },
    //         },
    //     ],
    // },
];

function reseEditRouter() {
    let tmpMenu = JSON.parse(localStorage.getItem("upmsMenu")) || []; //路由数据
    let tmpRoute = {};
    for (let i = 0; i < tmpMenu.length; i++) {
        let tmpUserMenu = tmpMenu[i];
        tmpRoute = {
            path: tmpUserMenu.path,
            component: Layout,
            name: tmpUserMenu.path.slice(1, tmpUserMenu.path.length),
            meta: { title: tmpUserMenu.menuName, icon: "el-icon-s-tools" },
            children: [],
        };
        tmpUserMenu.menuName == "规则引擎"
            ? (tmpRoute.meta.icon = "el-icon-s-check")
            : "";
        let childrenItem = {};
        if (tmpUserMenu.children && tmpUserMenu.children.length > 0) {
            for (let t = 0; t < tmpUserMenu.children.length; t++) {
                const tmpItem = tmpUserMenu.children[t];
                // tmpItem.path = tmpItem.path.slice(1,tmpItem.path.length)
                console.log(
                    `@/views${
                        (tmpUserMenu.path == "/vue"
                            ? "/system"
                            : tmpUserMenu.path) + tmpItem.path
                    }.vue`
                );
                childrenItem = {
                    path: window.__POWERED_BY_QIANKUN__
                        ? tmpItem.path
                        : tmpItem.path.slice(1, tmpItem.path.length),
                    name: tmpItem.path,
                    // component: () => import('@/views' + tmpUserMenu.path + tmpItem.path + '.vue'),
                    // `@/views/${view}`
                    component: (resolve) =>
                        require([
                            `@/views${
                                (tmpUserMenu.path == "/vue"
                                    ? "/system"
                                    : tmpUserMenu.path) + tmpItem.path
                            }.vue`,
                        ], resolve),
                    meta: {
                        title: tmpItem.menuName,
                        icon: "el-icon-s-marketing",
                    },
                };
                tmpRoute.children.push(childrenItem);
            }
        } else {
            tmpRoute.component = () =>
                import(
                    "@/views" + tmpUserMenu.path + "/" + tmpItem.path + ".vue"
                );
        }
        routes.push(tmpRoute);
    }
    localStorage.setItem("upmsRoutes", JSON.stringify(routes));
}

const prefix = window.__POWERED_BY_QIANKUN__ ? "/vue" : "";
const createRouter = () =>
    new Router({
        base: prefix,
        mode: "history",
        routes,
    });

addAlias(routes);
// console.log("子应用路由：");
// console.log(routes);

const router = createRouter();

function addAlias(routes) {
    if (!window.__POWERED_BY_QIANKUN__) return;
    for (let route of routes) {
        let path = route.path;
        //当路径为/xxx时，添加alias: /vue/xxx
        if (path == "/vue" && route.children) {
            // addAlias(route.children)
            route.children.forEach((ele) => {
                ele.alias = prefix + "/" + ele.path;
            });
            console.log(route.children);
        }
    }
}

//全局前置路由守卫-- 初始化的时候被调用,每次路由切换之前被调用
router.beforeEach((to, from, next) => {
    next();
});

/** 解决跳转重复路由报错问题   */
const originPush = router.push;
Router.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject);
    } else {
        originPush.call(
            this,
            location,
            () => {},
            () => {}
        );
    }
};

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
    router.options.routes = [];
}

export default router;
