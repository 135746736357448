import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import { flexible } from "./utils/flexible.js";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import "./assets/css/responsive.css";
import "@/assets/css/table_public.css"; // global css

import {
    getToken,
    intervalRefreshToken,
    setTimeToken,
    getTimeToken,
} from "./utils/auth.js";
import {
    parseTime,
    resetForm,
    addDateRange,
    selectDictLabel,
    selectDictLabels,
    handleTree,
} from "@/utils/ruoyi";
import plugins from "./plugins"; // plugins
// 分页组件
import Pagination from "@/components/Pagination";
// 全局组件挂载
Vue.component("Pagination", Pagination);
var _versions = store.state.version;
var baseURL = store.getters.baseURL;
if (localStorage.getItem("origin") && location.hostname != "localhost") {
    baseURL = localStorage.getItem("origin");
}
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.parseTime = parseTime;
Vue.prototype.handleTree = handleTree;
Vue.prototype.role = "";
Vue.prototype.userInfo = "";
Vue.prototype.$axios = axios;

Vue.prototype.instance = Vue.prototype.$axios.create({
    baseURL,
});
//请求拦截器
Vue.prototype.instance.interceptors.request.use(
    (config) => {
        //请求之前的操作  Authorization
        if (localStorage.getItem("X-From-Business-Line")) {
            config.headers["X-From-Business-Line"] = localStorage.getItem(
                "X-From-Business-Line"
            );
        } else {
            config.headers["X-From-Business-Line"] = "tpa";
            config.headers["X-From-Tenant"] = "9999";
        }
        if (localStorage.getItem("X-From-Tenant")) {
            config.headers["X-From-Tenant"] =
                localStorage.getItem("X-From-Tenant");
        }

        config.headers.Authorization = "Bearer " + getToken();
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
//响应拦截器
var index = 1;
Vue.prototype.instance.interceptors.response.use(
    (res) => {
        if (res.data.code == 401) {
            index == 1 &&
                window.alert("抱歉，当前账号登录信息已过期，请重新登录");
            index++;
            router.replace({
                path: "/",
                query: { redirect: router.currentRoute.fullPath }, //登录成功后跳入浏览的当前页面
            });
            setTimeout(() => {
                location.reload();
            }, 500);
        }
        return res;
    },
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    index == 1 &&
                        window.alert(
                            "抱歉，当前账号登录信息已过期，请重新登录"
                        );
                    index++;
                    router.replace({
                        path: "/",
                        query: { redirect: router.currentRoute.fullPath }, //登录成功后跳入浏览的当前页面
                    });
                    setTimeout(() => {
                        location.reload();
                    }, 500);
            }
        }
    }
);
Vue.config.productionTip = false;

// qiankun
let instance = null;

function render(props) {
    instance = new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount("#container"); //默认挂载自己的HTML，基座
}

if (window.__POWERED_BY_QIANKUN__) {
    __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
}

if (!window.__POWERED_BY_QIANKUN__) {
    // 如果是独立运行此项目，则直接运行
    render();
}

//子组件的协议
export async function bootstrap(props) {
    console.log("%c vue2", "color:blue");
}

export async function mount(props) {
    console.log("%c vue2 mount", "color:blue");
    render(props);
}

export async function unmount(props) {
    console.log("$c vue2 unmount", "color:blue");
    instance.$destroy();
}

//定时刷新token
intervalRefreshToken();
//路由跳转前做判断
router.beforeEach((to, from, next) => {
    let AUTH_TOKEN = getToken();
    if (AUTH_TOKEN) {
        next();
    } else {
        if (to.path == "/") {
            next();
        } else {
            next({
                replace: true,
                name: "Login",
            });
        }
    }
});
Vue.use(ElementUI, { size: "small", zIndex: 3000 });
Vue.use(plugins);
Vue.use(VueAxios, axios);
// Registering Syncfusion license key
// "Mgo+DSMBaFt/QHRqVVhkVFpHaV1CQmFJfFBmRmlYe1RwckU3HVdTRHRcQl9iQX5QdERnXX1acXM=;Mgo+DSMBPh8sVXJ0S0J+XE9AflRBQmFJYVF2R2BJfFRxcF9GZkwgOX1dQl9gSXxSfkVgW35adHZQQ2c=;ORg4AjUWIQA/Gnt2VVhkQlFacldJXnxLfkx0RWFab116dVFMYFpBNQtUQF1hSn5Rd01jWn1fdHBXQGdb;MTE1NDIwNkAzMjMwMmUzNDJlMzBFd2R2d0licVFrRkNwMXhjaS8wemhscDRtekl2TFRSUmYxa3B4b1NCYUlNPQ==;MTE1NDIwN0AzMjMwMmUzNDJlMzBSUjVBcmpBMVN2bjRIemkvS05XUUt4YklueWxNY1g2a3lTNTdSU3BlTmZRPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmBWfFNpR2NbfE5zflRCalxXVAciSV9jS31TdERrWXxccXBRRWNdUw==;MTE1NDIwOUAzMjMwMmUzNDJlMzBMTEVYQkZJZ3FMaEhFeS9XNjZVcGdzR0JXanVuMzhLWWplSnpMdGh6SVVrPQ==;MTE1NDIxMEAzMjMwMmUzNDJlMzBlM3lWZjRlaHlTSmtNNUV3VWdDUVhvSVcwdWNPSkZQRFZvcWdhRXpONFRZPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXnxLfkx0RWFab116dVFMYFpBNQtUQF1hSn5Rd01jWn1fdHBWT2Ve;MTE1NDIxMkAzMjMwMmUzNDJlMzBtSlBrWEhRYUVYV09FVlNtcXM1ZmtXaXdxbVFRT3VZTUxZWWxxQXE1eWVVPQ==;MTE1NDIxM0AzMjMwMmUzNDJlMzBhY0Faa1BYbzJsdVhYSmdxVDh3Q05PM3FMOWZXY2NBYzRMQ2VUdDNvSGlrPQ==;MTE1NDIxNEAzMjMwMmUzNDJlMzBMTEVYQkZJZ3FMaEhFeS9XNjZVcGdzR0JXanVuMzhLWWplSnpMdGh6SVVrPQ=="

var fontSize = 0;
var fontRem = 0;
// 获取 URL 参数
Vue.prototype.getQueryString = function (name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var href = window.location.href;
    href = decodeURI(href);
    var r = href.substr(href.indexOf("?") + 1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
};
//获取商品品类树option
Vue.prototype.getCategoryTree = function () {
    this.instance.get("/goods/category/getCategoryTree", {}).then((res) => {
        if (res.data.code == 200) {
            res.data.data.length > 0 &&
                res.data.data.forEach((val, key) => {
                    val.value = val.id;
                    val.label = val.name;
                    val.children = val.goodsCategoryDtoList;
                    val.children.length > 0 &&
                        val.children.forEach((value, index) => {
                            value.value = value.id;
                            value.label = value.name;
                        });
                });
            this.categoryTreeOption = res.data.data;
        }
    });
};
//获取枚举信息
Vue.prototype.getPublicEnum = function (params, key) {
    this.instance.get("/enum/" + params, {}).then((res) => {
        if (res.data.code == 200) {
            this[key] = res.data.data;
        }
    });
};

// 时间格式化
Vue.prototype.dateFormatting = function (date, fmt) {
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1
                    ? o[k]
                    : ("00" + o[k]).substr(("" + o[k]).length)
            );
    return fmt;
};
Array.prototype.indexOf = function (val) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] == val) return i;
    }
    return -1;
};
Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};
