<template>
    <div>
        <el-dialog width="80%" :title="ruleEditor.title" :modal-append-to-body="false" append-to-body center :visible="ruleEditor.visible" :before-close="handleClose">
            <div>
                <el-form ref="ruleForm" :model="ruleDetail" label-width="120px" :inline="true" class="demo-form-inline">
                    <el-form-item label="策略编号：">
                        <el-input v-model="ruleDetail.code" class="width_180" disabled />
                    </el-form-item>
                    <el-form-item label="策略名称：" :rules="{ required: true, message: '策略名称不能为空', trigger: 'blur' }">
                        <el-input v-model="ruleDetail.name" class="width_180" placeholder="请输入策略名称" />
                    </el-form-item>
                    <el-form-item label="策略分类：">
                        <el-input v-model="ruleDetail.scene" class="width_180" disabled />
                    </el-form-item>
                    <el-form-item label="策略描述：">
                        <el-input type="textarea" class="width_350" v-model="ruleDetail.desc" placeholder="请输入策略描述" />
                    </el-form-item>
                    <el-divider></el-divider>
                </el-form>
                <div>
                    <el-form ref="ruleForm2" :model="ruleDetail2" label-width="120px">
                        <el-form-item label="策略逻辑：">
                            <el-select v-model="ruleDetail2.name">
                                <el-option label="满足全部规则" value="满足全部规则"></el-option>
                                <el-option label="满足任意规则" value="满足任意规则"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <ejs-querybuilder width="100%" id="querybuilder" ref="exprEditor" :showButtons="showButtons" :columns="features" :dataSource="features"> </ejs-querybuilder>
                </div>
                <el-form label-width="180px">
                    <div v-if="activeName == '风控'">
                        <el-form-item label="命中策略执行动作：" class="rule_box">
                            <div v-for="(items, index) in actionData" :key="index" class="rule_div">
                                <el-select v-model="items.action" @change="actionChange" clearable placeholder="请选择">
                                    <el-option v-for="item in featureOption" :key="item.id" :label="item.featureName" :value="item.featureCode"></el-option>
                                </el-select>
                                <el-popover placement="top" width="450" trigger="hover">
                                    <ul class="action_detail">
                                        <li>风险名称：{{ featureDesc.riskName }}</li>
                                        <li>风险分类：{{ featureDesc.riskType }}</li>
                                        <li>风险步骤：{{ featureDesc.riskStep }}</li>
                                        <li>风险等级：{{ featureDesc.riskLevel }}</li>
                                        <li>风险类型：{{ featureDesc.riskCategory }}</li>
                                        <li>风险描述：{{ featureDesc.riskDesc }}</li>
                                    </ul>
                                    <el-button slot="reference" style="margin-left: 15px" v-show="featureDesc.riskName">执行动作详情</el-button>
                                </el-popover>
                                <!-- <el-button @click="deleteAction(items,index)" type="danger" icon="el-icon-delete" circle style="margin-left:20px;"></el-button>
                <el-button v-if="index==(actionData.length-1)" @click="addAction" type="primary" icon="el-icon-plus" circle style="margin-left:10px;"></el-button> -->
                                <el-button @click="addRiskPoint(items, index)" type="default" style="margin-left: 20px">添加新风险点</el-button>
                            </div>
                        </el-form-item>
                    </div>
                    <el-form-item label="策略执行动作：" class="rule_box" v-else>
                        <div v-for="(items, index) in action2Data" :key="index" class="rule_div">
                            <el-select v-model="items.action1" class="width_140" @change="action1Change(items)" clearable placeholder="请选择">
                                <el-option v-for="item in featureOption2" :key="item.id" :label="item.featureName" :value="item.featureCode"></el-option>
                            </el-select>
                            <el-select v-model="items.action2" class="width_100" disabled placeholder="请选择">
                                <el-option label="大于" value="大于"></el-option>
                                <el-option label="小于" value="小于"></el-option>
                                <el-option label="等于" value="等于"></el-option>
                            </el-select>
                            <el-input type="tel" v-model="items.value" class="width_100" clearable placeholder="请输入" />
                            <el-select v-model="items.unit" disabled class="width_100" placeholder="请选择">
                                <el-option label="%" value="%"></el-option>
                                <el-option label="元" value="元"></el-option>
                            </el-select>
                            <!-- <el-button @click="deleteAction2(items,index)" type="danger" icon="el-icon-delete" circle style="margin-left:20px;"></el-button>
              <el-button v-if="index==(action2Data.length-1)" @click="addAction2" type="primary" icon="el-icon-plus" circle style="margin-left:10px;"></el-button> -->
                        </div>
                    </el-form-item>
                    <!-- <el-form-item label="未命中策略执行动作：" class="rule_box">
						<div v-for="(items,index) in notActionData" :key="index" class="rule_div">
							<el-select v-model="items.action"  clearable placeholder="请选择">
								<el-option	label="流转至录入理算步骤" value="流转至录入理算步骤"></el-option>
								<el-option	label="流转至复审步骤" value="流转至复审步骤"></el-option>
								<el-option	label="流转至质检案件池" value="流转至质检案件池"></el-option>
							</el-select>
							<el-button @click="deleteNotAction(items,index)" type="danger" icon="el-icon-delete" circle style="margin-left:20px;"></el-button>
							<el-button v-if="index==(notActionData.length-1)" @click="addNotAction" type="primary" icon="el-icon-plus" circle style="margin-left:10px;"></el-button>
            </div>
					</el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="ruleEditor.visible = false">取 消</el-button>
                <el-button type="primary" @click="ruleUpload">确 定</el-button>
            </span>
            <el-dialog width="600px" title="添加风险点" :visible.sync="innerVisible" center append-to-body>
                <div class="dialog_box">
                    <el-form :model="riskPointForm" label-width="120px">
                        <el-form-item label="风险点名称：">
                            <el-input class="width_220" v-model="riskPointForm.riskName" placeholder="请输入" />
                        </el-form-item>
                        <el-form-item label="风险分类：">
                            <el-select v-model="riskPointForm.riskType" placeholder="请选择">
                                <el-option label="文本" value="文本"></el-option>
                                <el-option label="缺少图片" value="缺少图片"></el-option>
                                <el-option label="异常图片" value="异常图片"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="风险步骤：">
                            <el-select v-model="riskPointForm.riskStep" placeholder="请选择">
                                <el-option label="报案风险" value="报案风险"></el-option>
                                <el-option label="初审风险" value="初审风险"></el-option>
                                <el-option label="理算风险" value="理算风险"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="风险等级：">
                            <el-select v-model="riskPointForm.riskLevel" placeholder="请选择">
                                <el-option label="低" value="低"></el-option>
                                <el-option label="中" value="中"></el-option>
                                <el-option label="高" value="高"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="风险类型：">
                            <el-select v-model="riskPointForm.riskCategory" placeholder="请选择">
                                <el-option label="欺诈" value="A"></el-option>
                                <el-option label="免责" value="B"></el-option>
                                <el-option label="赔付减免" value="C"></el-option>
                                <el-option label="案件提示" value="D"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="风险描述：">
                            <el-input type="textarea" v-model="riskPointForm.riskDesc"></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer" style="margin-top: 30px; text-align: center">
                        <el-button @click="innerVisible = false">取 消</el-button>
                        <el-button type="primary" @click="submitRiskPoint">确 定</el-button>
                    </div>
                </div>
            </el-dialog>
        </el-dialog>
        <div class="wrap" v-loading="loading">
            <div class="page_title"><b>策略管理</b></div>
            <div class="table_box public_box">
                <el-tabs v-model="activeName" @tab-click="fetchRuleDataFrame(1)">
                    <el-tab-pane label="风控策略" name="风控"></el-tab-pane>
                    <el-tab-pane label="录入理算策略" name="理算"></el-tab-pane>
                </el-tabs>
                <div class="button_container">
                    <el-button type="primary" @click="editRules('')">新增</el-button>
                </div>
                <el-table :data="ruleDataFrame.data" style="width: 100%">
                    <template v-for="column in ruleTableOptions.columns">
                        <template v-if="column.prop == 'operators'">
                            <el-table-column :label="column.label" :prop="column.prop" :key="column.code">
                                <template scope="scope">
                                    <el-button type="primary" size="mini" @click="editRules(scope.row.strategyCode)">修改</el-button>
                                </template>
                            </el-table-column>
                        </template>
                        <template v-else-if="column.prop == 'status'">
                            <el-table-column :label="column.label" :prop="column.prop" :key="column.code">
                                <template slot-scope="scope">
                                    <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949" inactive-value="10" active-value="0" @change="onStatusChange(scope.row)"> </el-switch>
                                </template>
                            </el-table-column>
                        </template>
                        <template v-else-if="column.prop == 'ruleJson'">
                            <el-table-column :label="column.label" :prop="column.prop" :key="column.code" />
                        </template>
                        <template v-else>
                            <el-table-column :label="column.label" :prop="column.prop" :key="column.code" />
                        </template>
                    </template>
                </el-table>
                <el-pagination style="margin-top: 0.2rem; text-align: right" layout="total, prev, pager, next" :page-size="10" :total="ruleDataFrame.total" :current-page="ruleDataFrame.current" @current-change="fetchRuleDataFrame"> </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import * as Options from './options';
let inOperators = ['in', 'notin'];

export default {
    name: 'StrategyAdmin',
    components: {},
    data() {
        return {
            loading: false,
            innerVisible: false,
            activeName: '风控',
            ruleDataFrame: {
                data: [],
                total: 0
            },
            riskPointForm: {
                riskName: '', // 风险名称
                riskType: '', // 风险类型  文本、缺少图片、异常图片
                riskStep: '', // 风险步骤  报案风险、初审风险、理算风险
                riskLevel: '', // 风险等级  低、中、高
                riskCategory: '', // 风险类别  A 欺诈 B 免责 C 赔付减免  D 案件提示
                riskDesc: '' // 风险描述
            },
            actionData: [
                {
                    action: ''
                }
            ],
            action2Data: [
                {
                    action1: '',
                    action2: '等于',
                    value: '',
                    unit: ''
                }
            ],
            publicAction: {
                action: ''
            },
            publicAction2: {
                action1: '',
                action2: '',
                value: '',
                unit: ''
            },
            notActionData: [
                {
                    action: ''
                }
            ],
            featureDesc: {},
            featureOption: [],
            featureOption2: [],
            ruleDetail: {},
            ruleDetail2: {
                name: '满足所有规则'
            },
            features: [],
            ...Options,
            ruleEditor: {
                visible: false,
                title: '新增策略'
            },
            dataSource: Options.employeeData,
            values: ['Mr.', 'Mrs.'],
            showButtons: { ruleDelete: true, groupInsert: true, groupDelete: true },
            paymentTemplate: {
                create: (args) => {
                    console.log(args);
                    return createElement('input', { attrs: { type: 'text' } });
                },
                destroy: (args) => {
                    let multiselect = getComponent(document.getElementById(args.elementId), 'multiselect');
                    if (multiselect) multiselect.destroy();
                    let dropdownlist = getComponent(document.getElementById(args.elementId), 'dropdownlist');
                    if (dropdownlist) dropdownlist.destroy();
                },
                write: (args) => {
                    if (inOperators.indexOf(args.operator) > -1) {
                        let multiSelectObj = new MultiSelect({
                            dataSource: ds,
                            value: args.values,
                            mode: 'CheckBox',
                            placeholder: 'Select Transaction',
                            change: (e) => {
                                this.$refs.exprEditor.$el.ej2_instances[0].notifyChange(e.value, e.element);
                            }
                        });
                        multiSelectObj.appendTo('#' + args.elements.id);
                    } else {
                        let dropDownObj = new DropDownList({
                            dataSource: ds,
                            value: args.values,
                            change: (e) => {
                                this.$refs.exprEditor.$el.ej2_instances[0].notifyChange(e.itemData.value, e.element);
                            }
                        });
                        dropDownObj.appendTo('#' + args.elements.id);
                    }
                }
            }
        };
    },
    methods: {
        //添加动作
        addAction() {
            let publicAction = JSON.parse(JSON.stringify(this.publicAction));
            this.actionData.push(publicAction);
        },
        //删除动作
        deleteAction(items, index) {
            if (this.actionData.length > 1) {
                this.actionData.splice(index, 1);
            } else {
                this.$message.error('抱歉，需保留一条执行动作！');
            }
        },
        //添加动作
        addAction2() {
            let publicAction = JSON.parse(JSON.stringify(this.publicAction2));
            this.action2Data.push(publicAction);
        },
        //删除动作
        deleteAction2(items, index) {
            if (this.action2Data.length > 1) {
                this.action2Data.splice(index, 1);
            } else {
                this.$message.error('抱歉，需保留一条执行动作！');
            }
        },
        //点击添加命中新风险点
        addRiskPoint(items, index) {
            this.innerVisible = true;
        },
        //点击提交命中新风险点
        submitRiskPoint() {
            let riskPointForm = JSON.stringify(this.riskPointForm);
            let json = {
                scene: '风控',
                featureName: this.riskPointForm.riskName,
                featureDesc: riskPointForm
            };
            this.instance.post('/risk/api/feature/definition/add', json).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.message);
                    this.innerVisible = false;
                    this.getFeature();
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        //添加未命中动作
        addNotAction() {
            let publicAction = JSON.parse(JSON.stringify(this.publicAction));
            this.notActionData.push(publicAction);
        },
        action1Change(items) {
            items.unit = items.action1 == 'FEATURE1627607185521831936' ? '%' : '元';
        },
        actionChange(val) {
            let opt = {};
            opt = this.featureOption.find((item) => {
                return item.featureCode === val;
            });
            if (opt) {
                this.featureDesc = JSON.parse(opt.featureDesc);
            } else {
                this.featureDesc = {};
            }
        },
        //删除未命中动作
        deleteNotAction(items, index) {
            if (this.notActionData.length > 1) {
                this.notActionData.splice(index, 1);
            } else {
                this.$message.error('抱歉，需保留一条执行动作！');
            }
        },
        onStatusChange: function (row) {
            this.$data.loading = true;
            this.instance
                .post('/risk/api/strategy/definition/edit', { code: row.strategyCode, status: row.status })
                .then(({ data }) => {
                    this.$data.loading = false;
                    const operate = row.status == 0 ? '启用' : '停用';
                    if (data.code == 200) {
                        this.$message({ message: '策略' + row.strategyName + '已' + operate, type: 'success' });
                    } else {
                        this.$message.error('策略' + row.strategyName + operate + '失败！');
                    }
                })
                .catch((_) => (this.$data.loading = false));
        },
        editRules: function (ruleCode) {
            this.$data.ruleEditor.visible = true;
            this.$data.ruleDetail = {};
            this.$refs.exprEditor && this.$refs.exprEditor.ej2Instances.reset();
            if (ruleCode) {
                this.$data.loading = true;
                this.$data.ruleEditor.title = '编辑策略';
                this.instance
                    .post('/risk/api/strategy/definition/' + ruleCode)
                    .then(({ data }) => {
                        this.$data.loading = false;
                        const ruleDetail = data.data;
                        this.$data.ruleDetail = {
                            code: ruleDetail.code,
                            desc: ruleDetail.desc,
                            name: ruleDetail.name,
                            scene: ruleDetail.scene
                        };
                        console.log(ruleDetail);
                        this.$refs.exprEditor.ej2Instances.setRulesFromSql(ruleDetail.formula);
                    })
                    .catch((error) => {
                        this.$data.loading = false;
                        this.$message.error('加载策略详情失败，网络错误！');
                    });
            } else {
                this.$data.ruleEditor.title = '新增策略';
                this.$data.ruleDetail = {};
                this.$refs.exprEditor.ej2Instances.reset();
                this.$refs.exprEditor.ej2Instances.setRules({ condition: 'and', rules: [{}] });
                this.ruleDetail.scene = this.activeName;
            }
        },
        ruleUpload() {
            this.loading = true;
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let ruleDetail = JSON.parse(JSON.stringify(this.ruleDetail));
                    ruleDetail.scene = this.activeName;
                    let rule = {
                        ...ruleDetail,
                        output: {
                            failFeatureValue: '',
                            featureCode: '',
                            featureValue: ''
                        }
                    };
                    if (this.activeName == '风控') {
                        rule.output.featureCode = this.actionData[0].action;
                        let opt = {};
                        opt = this.featureOption.find((item) => {
                            return item.featureCode === this.actionData[0].action;
                        });
                        rule.output.featureValue = opt.featureName;
                    } else {
                        rule.output.featureCode = this.action2Data[0].action1;
                        rule.output.featureValue = this.action2Data[0].value + this.action2Data[0].unit;
                    }
                    const sqlExpr = this.$refs.exprEditor.ej2Instances.getSqlFromRules();
                    rule.formula = sqlExpr;
                    // console.log(this.ruleDetail.code)
                    let sign = this.ruleDetail.code ? 'edit' : 'config';
                    this.instance
                        .post('/risk/api/strategy/definition/' + sign, rule)
                        .then(({ data }) => {
                            this.$data.loading = false;
                            if (data.code == 200) {
                                this.$message({ message: '策略配置成功', type: 'success' });
                                this.ruleEditor.visible = false;
                                this.fetchRuleDataFrame(1);
                            } else {
                                this.$message({ message: data.message, type: 'error' });
                            }
                        })
                        .catch((err) => {
                            this.$data.loading = false;
                            this.$message({ message: '保存失败，网络错误', type: 'error' });
                        });
                } else {
                    this.$message({ message: '策略配置错误，无法提交，请重新填写！', type: 'error' });
                }
            });
        },

        handleClose: function () {
            this.ruleEditor.visible = false;
        },
        handleRuleChange: function () {
            console.log(this.$refs.exprEditor.ej2Instances.getSqlFromRules());
        },
        fetchRuleDataFrame(currentPage) {
            this.loading = true;
            let json = {
                condition: {
                    scene: this.activeName
                },
                pageNo: currentPage,
                pageSize: 10
            };
            this.instance.post('/risk/api/strategy/definition/paging', json).then((res) => {
                this.loading = false;
                this.ruleDataFrame = {
                    data: res.data.data.list,
                    total: parseInt(res.data.data.total),
                    current: parseInt(res.data.data.current)
                };
            });
        },
        //获取特征定义
        getFeature() {
            let json = {
                condition: {
                    scene: '风控'
                },
                pageNo: 1,
                pageSize: 100
            };
            this.instance.post('/risk/api/feature/definition/paging', json).then((res) => {
                if (res.data.code == 200) {
                    this.featureOption = res.data.data.list;
                }
            });
        },
        getFeature2() {
            let json = {
                condition: {
                    scene: '理算'
                },
                pageNo: 1,
                pageSize: 100
            };
            this.instance.post('/risk/api/feature/definition/paging', json).then((res) => {
                if (res.data.code == 200) {
                    this.featureOption2 = res.data.data.list;
                }
            });
        }
    },
    mounted() {
        //
        this.baseURL = this.$store.getters.baseURL;
        this.fetchRuleDataFrame(1);
        this.getFeature();
        this.getFeature2();
        this.instance.post('/risk/api/rule/definition/paging', { pageNo: 1, pageSize: 100 }).then(({ data }) => {
            const list = data.data.list;
            const columns = [];
            const values = list.map((item) => item.ruleName);
            for (var i = 0; i < list.length; i++) {
                const rule = list[i];
                columns.push({
                    field: rule.ruleCode,
                    label: rule.ruleName,
                    type: Options.getDataType(rule.dataType)
                    //template: this.paymentTemplate,
                    //values: values.filter(v => v != feature.featureName)
                });
            }
            this.$data.features = columns;
        });
    }
};
</script>


<style scoped>
.e-query-builder {
    margin: 0 auto;
}
.e-qb-button {
    margin: 2% 1% 0 15%;
}

.e-qbr-button {
    margin: 2% 1% 0 1%;
}

#datatable {
    margin-left: 15%;
    margin-top: 2%;
}
.rule_div {
    margin-bottom: 10px;
}
.action_detail {
    width: 100%;
    font-size: 12px;
    /* padding-left:180px; */
}
.action_detail li {
    margin-bottom: 10px;
}
.rule_box {
    margin-top: 20px;
}
.rule_box .rule_div:last-child {
    margin-bottom: 0;
}
#datatable.e-table {
    border: solid 1px #e0e0e0;
    border-collapse: collapse;
    font-family: Roboto;
}

#datatable.e-table td,
#datatable.e-table th {
    border: solid #e0e0e0;
    border-width: 1px 0 0;
    display: table-cell;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    padding: 8px 21px;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
}
.table_box {
    margin-bottom: 12px;
    margin-top: 10px;
}
.button_container {
    width: 100%;
    text-align: right;
    padding-bottom: 20px;
    padding-right: 40px;
}
</style>
