<template>
    <div class="page_content">
        <my-header @handleFoldMenus="handleFoldMenus" v-if="typeof isMicroEnc == 'undefined' && !isMicroEnc"></my-header>
        <my-menus :isCollapse="isCollapse" v-if="typeof isMicroEnc == 'undefined' && !isMicroEnc"></my-menus>
        <div :class="{ hideSidebar: isCollapse, main: 1 }">
            <div ref="iframe" class="iframe">
                <transition name="fade-transform" mode="out-in">
                    <router-view v-if="isVisible" :isCollapse="isCollapse" />
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import MyHeader from '@/components/MyHeader.vue';
import MyMenus from '@/components/MyMenus.vue';

export default {
    name: 'Layout',
    components: {
        MyHeader,
        MyMenus
    },
    data() {
        return {
            isCollapse: true,
            isVisible: true,
            isStyle: this.isMicroEnc
        };
    },
    computed: {
        key() {
            return this.$route.path;
        },
        isMicroEnc() {
            return window.__POWERED_BY_QIANKUN__;
        }
    },
    watch: {
        $route(to, from) {
            // 对路由变化作出响应...
            if (to.name == from.name) {
                this.isVisible = false;
                setTimeout(() => {
                    this.isVisible = true;
                }, 200);
            }
        }
    },
    methods: {
        handleFoldMenus(isFold) {
            this.isCollapse = isFold;
        }
    }
};
</script>
<style scoped>
.wrap {
    min-height: 100%;
    position: relative;
}
.wrap.homepage {
    background-color: rgb(38, 45, 60);
}
.main {
    width: calc(100% - 200px);
    /* height: calc(100vh - 0.8rem); */
    height: 90%;
    position: absolute;
    top: 0.8rem;
    left: 200px;
    z-index: 10;
    background-color: #f2f8fc;
    transition: all 0.6s;
}
.hideSidebar {
    width: calc(100% - 64px);
    left: 64px;
}
.iframe {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0.23rem 0.3rem 0.3rem;
}
.homepage .iframe {
    padding: 0;
}
</style>