<template>
    <div class="wrap">
        <div class="page_title"><b>用户管理</b></div>
        <div class="main public_box">
            <user-manage-hl></user-manage-hl>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import UserManageHl from '@/package/userManageHl/index.vue';
export default {
    name: 'userManage',
    components: {
        UserManageHl
    },
    data() {
        return {
            loading: false,
            menuType: 'searchReviewPage'
        };
    },
    methods: {},
    mounted() {
        //
    }
};
</script>
<style scoped>
.main {
    margin-top: 0.2rem;
}
</style>
