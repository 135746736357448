import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        version: "manager", //manager
    },
    getters: {
        baseTitle(state) {
            var version = state.version;
            return "京东物流TPA";
        },
        baseURL(state) {
            let hostJson = {
                operate: "operate-platform-test.sigreal.com.cn",
                jiaanan: "jiaanantest.platform.sigreal.cn",
                tpa: "cargo-tpa.test.sigreal.cn",
            };
            let href = "//" + location.hostname + "/manager";
            let businessLine =
                localStorage.getItem("X-From-Business-Line") || "tpa";

            if (location.hostname == "localhost") {
                href = `//${hostJson[businessLine]}/manager`;
            }
            return href;
        },
    },
    mutations: {},
    actions: {},
    modules: {},
});
